$weight-extralign: 200;
$weight-light: 300;
$weight-book: 400;
$weight-bold: 700;
$weight-extrabold: 900;

$font-color: #4a4a4a;
$title-color: #8C7157;
$primary-color: #C3A07D;
$light-text-color: #6C6463;
$background-color: #f5f5f5;

$border-color: #d1d1d1;
$default-border: 1px solid $border-color;