@import "./variables.scss";

@mixin color-variants {
  &.danger {
    background-color: $danger;
    color: white;
  }
  &.warning {
    background-color: $warning;
  }
  &.info {
    background-color: $info;
  }
  &.success {
    background-color: $success;
    color: white;
  }
}

@mixin visible {
  display: none;
  &.visible {
    display: block;
  }
}

@mixin breakpoint($point) {
  @if $point == tablet {
    @media (max-width: 960px) { @content ; }
  }
  @if $point == mobile {
    @media (max-width: 600px) { @content ; }
  }
}

@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}