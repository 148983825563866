@import "~style/variables";
@import "~style/mixins";

#page-estimate {
  .estimation-container {
    position: relative;
  }
  button.cta {
    width: calc(40% - 10px);
    position: absolute;
    top: 0;
    right: 0;
  }
  h4 {
    font-size: 18px;
    margin: 0 20px 0 0;
    width: calc(60% - 10px);
  }
  .disclaimer {
    width: 100%;
    font-size: 13px;
    margin: 20px 0;
    text-align: left;
  }
  button.next {
    position: absolute;
    right: 23px;
    width: 275px;
    height: 40px;
    text-align: center;
    display: block;
    margin-top: 23px;
    @include breakpoint(mobile) {
      position: relative;
      right: unset;
      margin-left: auto;
      margin-bottom: 20px;
      width: 100%;
      max-width: unset;
    }
    &:nth-last-of-type(2) {
      margin-top: 68px;
    }
  }
  .window-url {
    display: none;
  }
  .print-footer {
    display: none;
  }
  @include breakpoint(mobile) {
    .estimation-container {
      display: flex;
      flex-direction: column;
      & > * {
        order: 99;
      }
      button.cta {
        position: relative;
        width: 100%;
        margin-top: 20px;
      }
      & > h2 {
        order: 1;
      }
      & > h4 {
        order: 2;
        width: 100%;
      }
      & > .estimation-box {
        order: 3;
        margin-top: 20px;
      }
    }
  }
}

.rtl {
  #page-estimate {
    button.cta {
      right: unset;
      left: 0;
    }
    button.next {
      right: unset;
      left: 20px;
    }
    h4 {
      margin: 0 0 20px 0;
    }
  } 
}

.print {
  @page { margin: 0 40px; }
  margin: 0 40px;
  .header {
    margin-top: 50px;
  }
  #page-estimate {
    .navbar, .sidebar, .columns, .footer, button, button.next {
      display: none
    }
    .print-footer {
      display: block;
      text-align: center;
      color: $title-color;
      margin-top: 50px;
      border-top: $default-border;
      padding-top: 20px;
      font-size: 10px;
      width: 100%;
    }
    h2 {
      margin-bottom: 50px;
    }
    .estimation-box {
      width: 80vw;
      border: none;
      padding: 0;
      .logo {
        display: none;
      }
    }
  }
}
