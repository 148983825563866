@charset "UTF-8";
@font-face {
  font-family: PreloCondensed;
  font-weight: 200;
  src: url(./fonts/PreloCondensedExtraLight.otf) format("opentype");
}
@font-face {
  font-family: PreloCondensed;
  font-weight: 300;
  src: url(./fonts/PreloCondensedLight.otf) format("opentype");
}
@font-face {
  font-family: PreloCondensed;
  font-weight: 400;
  src: url(./fonts/PreloCondensedBook.otf) format("opentype");
}
@font-face {
  font-family: PreloCondensed;
  font-weight: 700;
  src: url(./fonts/PreloCondensedMedium.otf) format("opentype");
}
@font-face {
  font-family: PreloCondensed;
  font-weight: 900;
  src: url(./fonts/PreloCondensedExtraBold.otf) format("opentype");
}
@font-face {
  font-family: Prelo;
  font-weight: 200;
  src: url('./fonts/Prelo-ExtraLight.woff2') format('woff2'), url('./fonts/Prelo-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: Prelo;
  font-weight: 200;
  font-style: italic;
  src: url('./fonts/Prelo-ExtraLightItalic.woff2') format('woff2'), url('./fonts/Prelo-ExtraLightItalic.woff') format('woff');
}
@font-face {
  font-family: Prelo;
  font-weight: 300;
  src: url('./fonts/Prelo-Light.woff2') format('woff2'), url('./fonts/Prelo-Light.woff') format('woff');
}
@font-face {
  font-family: Prelo;
  font-weight: 300;
  font-style: italic;
  src: url('./fonts/Prelo-LightItalic.woff2') format('woff2'), url('./fonts/Prelo-LightItalic.woff') format('woff');
}
@font-face {
  font-family: Prelo;
  font-weight: 400;
  src: url('./fonts/Prelo-Book.woff2') format('woff2'), url('./fonts/Prelo-Book.woff') format('woff');
}
@font-face {
  font-family: Prelo;
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/Prelo-BookItalic.woff2') format('woff2'), url('./fonts/Prelo-BookItalic.woff') format('woff');
}
@font-face {
  font-family: Prelo;
  font-weight: 700;
  src: url('./fonts/Prelo-Medium.woff2') format('woff2'), url('./fonts/Prelo-Medium.woff') format('woff');
}
@font-face {
  font-family: Prelo;
  font-weight: 700;
  font-style: italic;
  src: url('./fonts/Prelo-MediumItalic.woff2') format('woff2'), url('./fonts/Prelo-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: Prelo;
  font-weight: 900;
  src: url('./fonts/Prelo-ExtraBold.woff2') format('woff2'), url('./fonts/Prelo-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: Prelo;
  font-weight: 900;
  src: url('./fonts/Prelo-ExtraBoldItalic.woff2') format('woff2'), url('./fonts/Prelo-ExtraBoldItalic.woff') format('woff');
}

:root {
  font-family: Prelo;
  font-weight: normal;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
}