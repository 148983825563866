@import "./fonts.scss";
@import "./icons.scss";
@import "./helpers.scss";
@import "./variables.scss";
@import "./mixins.scss";

body {
  margin: 0;
  font-family: Prelo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #383838;
}

#root {
  width:100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  & > div {
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    margin-bottom: auto;
  }
}

button, select {
  cursor: pointer;
  font-family: Prelo;
}
select {
  @include ie {
    padding: 0 !important;
  }
}

.rtl {
  direction: rtl;
  & * {
    text-align: right !important;
  }
}